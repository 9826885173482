import DPA, { connect } from 'servisofts-page';
import { Parent } from '.';
import { SNavigation, SPopup, SText } from 'servisofts-component';
import Model from '../../Model';
import SSocket from 'servisofts-socket'
class index extends DPA.edit {
    constructor(props) {
        super(props, {
            Parent: Parent,
            excludes: ["key_company"]
        });
        this.state = {
            ubicacion: null
        };
    }
    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "edit" })
    }
    $getData() {
        return Parent.model.Action.getByKey(this.pk);
    }
    $inputs() {
        var inp = super.$inputs();
        inp["telefono"].type = "phone"
        inp["capacidad_envio"].type = "number"
        inp["porcentaje_comision_online"].required = true
        inp["porcentaje_comision_online"].type = "money"
        inp["porcentaje_comision_online"].icon = <SText>{"%"}</SText>

        if (inp["porcentaje_comision_online"]?.defaultValue) {
            inp["porcentaje_comision_online"].defaultValue = parseFloat(inp["porcentaje_comision_online"]?.defaultValue ?? 0).toFixed(2)
        }

        inp["porcentaje_comision_efectivo"].required = true
        inp["porcentaje_comision_efectivo"].type = "money"
        inp["porcentaje_comision_efectivo"].icon = <SText>{"%"}</SText>

        if (inp["porcentaje_comision_efectivo"]?.defaultValue) {
            inp["porcentaje_comision_efectivo"].defaultValue = parseFloat(inp["porcentaje_comision_efectivo"]?.defaultValue ?? 0).toFixed(2)
        }

        inp["delivery"].type = "select"
        inp["delivery"].options = [{ key: "", content: "SELECCIONAR" }, { key: "true", content: "SI" }, { key: "false", content: "NO" }]

        inp["recoger"].type = "select"
        inp["recoger"].options = [{ key: "", content: "SELECCIONAR" }, { key: "true", content: "SI" }, { key: "false", content: "NO" }]

        if (inp["porcentaje_comision_efectivo"]?.defaultValue) {
            inp["delivery"].defaultValue = JSON.parse(inp["delivery"]?.defaultValue) == true ? "true" : "false"
        }

        inp["latitude"].onPress = (evt) => {
            SNavigation.navigate("/restaurante/mapa", {
                callback: (resp) => {
                    console.log(resp)
                    this.setState({ ubicacion: resp })
                }
            });
        }

        if (this.state?.ubicacion?.latitude) inp["latitude"].value = this.state?.ubicacion?.latitude + ", " + this.state?.ubicacion?.longitude

        inp["foto_portada"] = {
            label: "Portada",
            key: "foto_portada",
            type: "image",
            defaultValue: SSocket.api.root + "restaurante/portada/" + this.pk,
            col: "xs-12",
            style: {
                height: 400,
            }
        }
        return inp;
    }
    $onSubmitFile() {
        this.form.uploadFiles(
            SSocket.api.root + "upload/restaurante/" + this.pk,
            "image_profile"
        );
        this.form.uploadFiles(
            SSocket.api.root + "upload/restaurante/portada/" + this.pk,
            "foto_portada"
        );
        // super.$onSubmitFile();
        // this.form.uploadFiles(
        //     SSocket.api.root + "upload/restaurante/portada/" + this.pk,
        //     "foto_portada"
        // );
        // this.form.uploadFiles2(
        //     SSocket.api.root + "upload/restaurante/portada/" + this.pk,
        // );
    }
    $onSubmit(data) {
        if (this.state?.ubicacion?.latitude) {
            data.latitude = this.state?.ubicacion?.latitude;
            data.longitude = this.state?.ubicacion?.longitude;
        }

        if(data?.key_company == ""){
            data.key_company = null;
        }

        Parent.model.Action.editar({
            data: {
                ...this.data,
                ...data
            },
            key_usuario: ""
        }).then((resp) => {
            SNavigation.goBack();
        }).catch(e => {
            console.error(e);
        })
    }
}

export default connect(index);