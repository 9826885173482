import { SPage } from 'servisofts-component';

import Model from '../../Model';

import root from './root';
import send from "./send"
// import list from './list';
import _default from './default';
import task from './task';
import notification_suscription_config from './notification_suscription_config';

const model = Model.notification;

export const Parent = {
    name: "notification",
    path: "/notification",
    model
}
export default SPage.combinePages(Parent.name, {
    "": root,
    // "list": list,
    send,
    ..._default,
    ...task,
    ...notification_suscription_config
});