export default {
    debug: true, //para que se vea los returns del server
    name: 'tapeke',

    ssl: true,
    host: 'tapeke.servisofts.com',

    // ssl: true,
    // host: 'test.tapeke.servisofts.com',

    // ssl: false,
    // host: '10.83.253.250',

    // ssl: false,
    // host: '192.168.3.2', 

    // ssl: false,
    // host: '192.168.5.31',

    // ssl: false,
    // host: '192.168.2.1',

    port: {
        native: 10031,
        web: 20031,
        http: 30031,
    },
    cert: 'SOLO-MOBILE-PEM-(NO_QUITAR!!!)',
    secret_key_aes: "5cc8d4fd-8b43-4412-9d41-f9f21c9aa050",
    apis: {
        roles_permisos: 'https://rolespermisos.servisofts.com/http/',
        empresa: 'https://empresa.servisofts.com/http/',
        inventario: 'https://inventario.servisofts.com/http/',
        chat: 'https://chat.servisofts.com/http/',
        // chat: "https://192.168.0.199:30009/"
    },
    timeReconnect: 5000,
};
