import { SModel } from "servisofts-model";
import Action from "./Action";
import Reducer from "./Reducer";

export default new SModel<Action, Reducer>({
    info: {
        component: "restaurante"
    },
    Columns: {
        "key": { type: "text", pk: true },
        "key_usuario": { type: "text", fk: "usuario" },
        "fecha_on": { type: "timestamp", label: "Fecha de creacion" },
        "estado": { type: "integer" },
        "nombre": { type: "text", editable: true, notNull: true, label: "Nombre" },
        "nit": { type: "text", editable: true, notNull: true, label: "Nit" },
        "telefono": { type: "text", editable: true, notNull: true, label: "Teléfono" },
        "descripcion": { type: "text", editable: true, label: "Descripción" },

        "direccion": { type: "text", editable: true, notNull: true, label: "Dirección textual" },
        "latitude": { type: "double", editable: true, notNull: true, label: "Ubicación en mapa" },
        // TODO no tiene que ir.
        // "longitude": { type: "double", editable: true, notNull: true , label: "Ubicación en mapa (longitude)" },

        "capacidad_envio": { type: "double", editable: true, notNull: true, label: "Capacidad de envío" },
        "porcentaje_comision_efectivo": { type: "double", editable: true, notNull: true },
        "porcentaje_comision_online": { type: "double", editable: true, notNull: true },



        "delivery": { type: "boolean", editable: true },
        "recoger": { type: "boolean", editable: true },

        "nombre_representante": { type: "text", editable: true, notNull: true },

        "foto_portada": { type: "text", editable: true },
        "key_company": { type: "text", editable: true, fk:"company" },
    },
    image: {
        api: "root",
        name: "restaurante"
    },
    Action,
    Reducer,
});