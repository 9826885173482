import { Text, View } from 'react-native'
import React, { Component } from 'react'
import { SDate, SLoad, SNavigation, SPage, SPopup, STable2, SView } from 'servisofts-component'
import SSocket from 'servisofts-socket'
import { BuildCustomHeader } from '.'
import { connect } from 'react-redux'
import Model from '../../../Model'
class index extends Component {
  state = {
    data: {}
  }
  constructor(props) {
    super(props);
    this.params = SNavigation.getAllParams() ?? {}
  }

  componentDidMount() {
    this.setState({ loading: true, error: "" })
    SSocket.sendPromise({
      component: "reporte",
      type: "executeFile",
      src: "query/reporte/cupones.pgsql",
      params: [this.params.fecha_inicio, this.params.fecha_fin]
    }).then(e => {
      let keys = [...new Set(Object.values(e.data).map(a => a.key_usuario).filter(key => key !== null))];
      SSocket.sendPromise({
        version: "2.0",
        service: "usuario",
        component: "usuario",
        type: "getAllKeys",
        keys: keys,
      }).then(e2 => {
        Object.values(e.data).map(a => {
          a.usuario = e2?.data[a.key_usuario]?.usuario ?? {}
          a.usuario_custodio = e2?.data[a.key_custodio]?.usuario ?? {}
        })
        this.setState({ data: e.data })
      }).catch(e2 => {
        SPopup.alert(e2.error)
      })
      this.setState({ loading: false, error: "", data: e.data })
    }).catch(e => {
      SPopup.alert(e.error)
      this.setState({ loading: false, error: e.error })
    })
  }

  renderTable() {
    if (!this.state.data) return <SLoad />

    return <STable2
      header={
        [
          { key: "fecha_on", label: "fecha", width: 140, order: "desc", render: val => new SDate(val).toString("yyyy-MM-dd hh:mm") },
          { key: "usuario_custodio", label: "cliente", width: 200, render: (val) => Object.keys(val).length > 0 ? `${val?.Nombres} ${val?.Apellidos}` : "Usuario eliminado" },
          { key: "descripcion", label: "cupon", width: 200, },
          { key: "estado", width: 100, render: val => val == 1 ? "ASIGNADO" : (val == 2 ? "CANJEADO" : (val == 3 ? "USADO" : val)) },
          { key: "monto", width: 100, sumar: true },
          { key: "monto_pedido", width: 100, },
          { key: "monto_minimo_compra", width: 100, },
          { key: "state", width: 100, },
          { key: "usuario", label: "cliente", width: 200, render: (val) => Object.keys(val).length > 0 ? `${val?.Nombres} ${val?.Apellidos}` : "Usuario eliminado" },

        ]
      }
      data={this.state.data} />
  }
  render() {
    return <SPage
      title={`Reporte de cupones desde ${this.params.fecha_inicio} hasta ${this.params.fecha_fin}`}
      disableScroll
    >{this.renderTable()}</SPage>
  }
}
const initStates = (state) => {
  return { state }
};
export default connect(initStates)(index);